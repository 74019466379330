import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/v4-shims.scss'
import {Tooltip,Toast} from 'bootstrap'

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
} catch (e) {

}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/**
 * Shows the toast passed by argument
 * @param {Element} el the toast to be initialized
 * @param {{animation: boolean, autohide: boolean, delay: number}} options the options to be used. Defaults: {animation: true, autohide: true, delay: 5000}
 */
Window.prototype.toast = function(el, options){
    let toastEl = new Toast(el, options)
    toastEl.show()
}

/**
 * Muestra el cuadro de mensajes de éxito
 * @param {string} text el texto a mostrar
 * @param {boolean} close valor que indica si se debe cerrar el mensaje luego de 3 segundos
 */
Window.prototype.showSuccess = function(text, close = false){
    let msg = document.getElementById('msg')
    msg.innerHTML = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px">\n' +
        '<div class="toast-header bg-success text-white">\n' +
        '<strong class="me-auto">Success</strong>\n' +
        '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
        '</div>\n' +
        '<div class="toast-body">'+text+'</div>\n' +
        '</div>'
    this.toast(msg.children.item(0), {
        animation: true,
        autohide: close,
        delay: 5000
    })
}

/**
 * Muestra el cuadro de mensajes de información
 * @param {string} text el texto a mostrar
 * @param {boolean} close valor que indica si se debe cerrar el mensaje luego de 3 segundos
 */
Window.prototype.showInfo = function(text, close = false){
    let msg = document.getElementById('msg')
    msg.innerHTML = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px">\n' +
        '<div class="toast-header bg-info text-white">\n' +
        '<strong class="me-auto">Info</strong>\n' +
        '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
        '</div>\n' +
        '<div class="toast-body">'+text+'</div>\n' +
        '</div>';
    this.toast(msg.children.item(0), {
        animation: true,
        autohide: close,
        delay: 5000
    })
}

/**
 * Muestra el cuadro de mensajes de error
 * @param {string} text el texto a mostrar
 * @param {boolean} close valor que indica si se debe cerrar el mensaje luego de 3 segundos
 */
Window.prototype.showError = function(text, close = false){
    let msg = document.getElementById('msg')
    msg.innerHTML = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px">\n' +
        '<div class="toast-header bg-danger text-white">\n' +
        '<strong class="me-auto">Error</strong>\n' +
        '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
        '</div>\n' +
        '<div class="toast-body">'+text+'</div>\n' +
        '</div>';
    this.toast(msg.children.item(0), {
        animation: true,
        autohide: close,
        delay: 5000
    })
}

/**
 * Muestra el cuadro de mensajes de advertencia
 * @param {string} text el texto a mostrar
 * @param {boolean} close valor que indica si se debe cerrar el mensaje luego de 3 segundos
 */
Window.prototype.showWarning = function(text, close = false){
    let msg = document.getElementById('msg')
    msg.innerHTML = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px">\n' +
        '<div class="toast-header bg-warning text-white">\n' +
        '<strong class="me-auto">Warning</strong>\n' +
        '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
        '</div>\n' +
        '<div class="toast-body">'+text+'</div>\n' +
        '</div>';
    this.toast(msg.children.item(0), {
        animation: true,
        autohide: close,
        delay: 5000
    })
}
